.pricing-wrapper { display: block; padding: 100px 0px 0px 0px; }
	.pricing { display: block; }
		.pricing-title { display: block; background: -webkit-linear-gradient( -70deg, #D94A8F 0%, #34CBCB 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; -webkit-box-decoration-break: clone; font-size: 64px; padding-bottom: 30px; font-weight: 700; margin: 0 auto 80px auto; text-align: center; width: max-content;
			@media only screen and (max-width: 1000px) { font-size: 48px; } }
		.pricing-container { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-column-gap: 30px; grid-row-gap: 30px; align-items: center; position: relative;
			@media only screen and (max-width: 1200px) { grid-template-columns: 1fr 1fr; grid-column-gap: 30px; grid-row-gap: 30px; }
			@media only screen and (max-width: 600px) { grid-template-columns: 1fr; } }
			.pricing-container-background1 { position: absolute; z-index: -1; left: -100px; }
			.pricing-container-background2 { position: absolute; z-index: -1; right: 100px; bottom: 0px;
				@media only screen and (max-width: 1000px) { right: 20%; } }
			.pricing-card { display: block; padding: 30px 30px; width: 80%; border-radius: 10px; bottom: 0px; transition: 0.5s;
				@media only screen and (max-width: 600px) { margin: 0 auto; } }
			.pricing-card-focused { background-color: #081C3D; }
				.pricing-card-title { display: block; font-size: 24px; margin-bottom: 40px; }
				.pricing-card-price-container { display: flex; margin-bottom: 40px; align-items: center; }
					.pricing-card-price { display: block; font-size: 36px; font-weight: bold; }
					.pricing-card-price-text { margin-left: 10px; }
				.pricing-card-text { display: block; margin-bottom: 30px; line-height: 1.3em; }
				.pricing-card-features { display: block; padding: 0px 15px; }
					.pricing-card-freature { display: flex; margin-bottom: 30px; }
						.pricing-card-feature-img { width: 18px; }
						.pricing-card-feature-text { margin-left: 20px; }
				.pricing-card-button { display: block; margin: 0 auto; width: 100%; padding: 15px 0px; border-radius: 10px; border: none; font-size: 16px; font-weight: 600; cursor: pointer; transition: 0.5s; color: black;
					&:hover { background: rgb(217,74,143); background: linear-gradient(338deg, rgba(217,74,143,1) 0%, rgba(4,209,253,0.90) 100%, rgba(0,212,255,0.95) 100%); color: white; } }