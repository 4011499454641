.card { width: 80%; border: 1px solid #A7A7A7; display: flex; padding: 30px 0px; justify-content: space-evenly; align-items: center; border-radius: 20px; margin: 0 auto;
	@media only screen and (max-width: 1000px) { flex-direction: column-reverse; text-align: center; } }
	.card-text { display: block; width: 40%;
		@media only screen and (max-width: 1000px) { width: 80%; } }
		.card-text-title { display: block; font-size: 36px; width: 80%; font-weight: 700; line-height: 30px; margin-bottom: 30px; color: #70819D;
			@media only screen and (max-width: 1000px) { margin: 0px auto 20px auto; } }
		.card-text-text { display: block; margin-bottom: 20px; color: #8d8d8d; }
		.card-text-buttons { display: flex; }
			.card-text-button { display: block; color: black; width: 40%; padding: 15px 15px; text-align: center; cursor: pointer; margin-left: 10px; border-radius: 5px; transition: 0.2s;
				&:hover { box-shadow:inset 0px 0px 0px 2px #000; } }
				.card-text-button-left { box-shadow:inset 0px 0px 0px 1px #A7A7A7; font-weight: 600; }
				.card-text-button-right { transition: 0.2s; border: 1px solid white; }
	.card-img { display: block; width: 30%;
		@media only screen and (max-width: 1000px) { width: 70%; margin-bottom: 30px; } }
		.card-img-img { display: block; width: 100%; }
