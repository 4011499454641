.hire-wrapper { display: block; padding: 100px 0px 0px 0px; }
	.hire { display: block; }
		.hire-title { display: block; background: -webkit-linear-gradient( -70deg, #D94A8F 0%, #34CBCB 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; -webkit-box-decoration-break: clone; font-size: 64px; padding-bottom: 30px; font-weight: 700; margin: 0 auto 100px auto; text-align: center; width: 60%;
			@media only screen and (max-width: 1000px) { font-size: 36px; margin-bottom: 30px; width: 70%; } }
		.hire-container { display: flex; position: relative;
			@media only screen and (max-width: 600px) { flex-direction: column-reverse; } }
			.hire-container-background { position: absolute; z-index: -1; right: 100px; bottom: 0px; }
			.hire-form-container { display: block; width: 100%; margin-right: 5%; padding-right: 5%; border-right: 1px solid #a7a7a7; align-items: center;
				@media only screen and (max-width: 600px) { border: none; } }
				.hire-form-title-container { display: block; font-size: 30px; font-weight: 600; padding-bottom: 5px; margin-bottom: 20px;
					@media only screen and (max-width: 600px)  { text-align: center; } }
				.hire-form { display: block; }
					.hire-form-input { background-color: transparent; border: none; border-bottom: 1px solid #a7a7a7; padding: 10px 0px; width: 46%; font-weight: 600; color: white; margin: 10px 0px; font-size: 16px; }
					.hire-form-input-name-container { display: flex; justify-content: space-between; }
						.hire-form-input-name { display: block; }
					.hire-form-button { display: block; width: 100%; padding: 10px; text-align: center; margin-top: 30px; font-weight: 800; background-color: white; border: none; border-radius: 5px; cursor: pointer; transition: 0.2s; background-color: #081C3D; color: white;
						&:hover { color: #040F21; background-color: #34CBCB; } }
			.hire-text-container { display: block; width: 50%;
				@media only screen and (max-width: 600px) {width: 80%; margin: 0 auto; text-align: center; margin-bottom: 50px; } }
				.hire-text-title { display: block; font-weight: 700; font-size: 30px; margin-bottom: 30px; color: #D94A8F;
					@media only screen and (max-width: 600px)  { display: none;} }
					.hire-text-text { display: block; margin-bottom: 20px; }