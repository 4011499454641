@use './library.scss' as lib;

.login-wrapper { display: block; 
	@media only screen and (max-width: 1200px) { width: 100%; } }
	.login { display: block; position: relative; width: 50%; margin: 50px auto 0px auto; padding: 10% 10% calc(98px + lib.$footer-size) 10%; 
		@media only screen and (max-width: 700px) { width: 70%; } }
		.login-form { display: block; }
		.login-form-password-container { display: flex; position: relative; width: 100%; }
			.login-form-input { display: block; width: 100%; padding: 10px 0px 10px 10px; box-sizing: border-box; border: 2px solid white; background-color: transparent; margin-top: 30px; border-radius: 5px; color: white; font-weight: 600; }
			.login-form-button { display: block; width: 100%; border: none; padding: 10px 0px 10px 10px; margin-top: 50px; border-radius: 5px; font-weight: 800; cursor: pointer; transition: 0.2s;
				&:hover { background-color: #D94A8F }}
			.login-form-eye { position: absolute; height: 40%; right: 10px; bottom: 6px; cursor: pointer; }
		.login-register { display: block; text-align: center; margin-top: 20px; }

.recaptcha { position: absolute; bottom: lib.$footer-size; left: 50%; transform: translate(-50%, 0);}