.hero-wrapper { margin-top: 150px;
	@media only screen and (max-width: 900px) { margin-top: 50px; } }
	.hero { display: flex; align-items: center; justify-content: space-between; position: relative;
		@media only screen and (max-width: 900px) { flex-direction: column-reverse; text-align: center; } }
		.hero-text { display: block; width: 50%;
			@media only screen and (max-width: 900px) { width: 80%; font-size: 36px; } }
			.hero-text-title { display: block; background: -webkit-linear-gradient( -70deg, #D94A8F 0%, #34CBCB 100%); -webkit-background-clip: text; background-clip: text; -webkit-text-fill-color: transparent; -webkit-box-decoration-break: clone; font-size: 64px; padding-bottom: 30px; font-weight: 700;
				@media only screen and (max-width: 1000px) { font-size: 36px; } }
			.hero-text-p { display: block; color: #8193B2; font-size: 24px; margin-bottom: 30px; line-height: 30px;
				@media only screen and (max-width: 1000px) { font-size: 18px; line-height: 25px; } }
			.hero-text-form { display: flex; width: 100%; }
				.hero-text-form-email { display: block; width: 60%; border-radius: 10px; margin-right: 1%; border: none; padding-left:20px; font-size: 18px; }
				.hero-text-form-button { display: block; width: 37%; border: none; border-radius: 10px; height: 50px; font-size: 18px; background-color: #2EA44F; color: white; cursor: pointer; transition: 0.2s; opacity: 0.6;
					&:hover { opacity: 1; } }
			.hero-medias { display: block; width: calc(60% + 20px); display: flex; justify-content: space-between; margin-top: 20px;cursor: pointer;
				@media only screen and (max-width: 600px) { flex-direction: column; }
				@media only screen and (max-width: 900px) { font-size: 20px; width: 100%; }
				@media only screen and (max-width: 1200px) { width: 100%; }}
				.hero-media { display: flex; align-items: center; justify-content: space-around; background-color: #061630; padding: 10px 10px; border-radius: 7px; font-weight: 500; transition: 0.2s;
					&:hover { background-color: #0a2756; }
					@media only screen and (max-width: 600px) { width: 50%; margin: 0 auto; margin-top: 10px; }}
					.hero-media-img { height: 28px; }
					.hero-media-name { margin-left: 10px; }
		.hero-img { display: block; }
			.hero-img-svg { display: block; width: 400px;
				@media only screen and (max-width: 900px) { width: 70%; margin: 0px auto 20px auto; } }
	.hero-information { display: block; margin-top: 150px;
		@media only screen and (max-width: 900px) { margin-top: 50px; } }
		.hero-line { width: 100%; height: 1px; background-color: #3A4251; opacity: 0.2; }
		.hero-information-texts { display: flex; margin-top: 30px; 
			@media only screen and (max-width: 900px) { display: grid;grid-template-columns: 1fr 1fr; grid-column-gap: 20px; grid-row-gap: 20px; align-items: center; text-align: center; } }
			.hero-information-text { display: block; margin: 0px 20px; font-size: 20px; }
				.hero-information-text-up { display: block; }
				.hero-information-text-down { display: block; color: #8193B2; font-size: 16px; margin-top: 10px; }
			.hero-information-line { width: 1px; height: 45px; background-color: #3A4251; opacity: 0.2;
				@media only screen and (max-width: 900px) { display: none; } }