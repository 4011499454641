.faq-wrapper {  }
	.section-curve { width: 100%; margin-bottom: 0px; position: relative; top: 3px; }
	.faq-content { width: 100%; background-color: white; padding: 20px 0px 20px 0px; }
		.faq { color: white; }
			.faq-title { display: block; background: -webkit-linear-gradient( -70deg, #D94A8F 0%, #34CBCB 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; -webkit-box-decoration-break: clone; font-size: 64px; padding-bottom: 30px; font-weight: 700; margin: 0 auto 50px auto; text-align: center; width: max-content;
				@media only screen and (max-width: 1000px) { font-size: 48px; } }
			.faq-container { position: relative; }
				.faq-items { display: block; width: 70%; margin: 0 auto; }
					.faq-item { }
						.faq-item-question { display: flex; padding: 20px 30px 20px 20px; background-color: #081C3D; border-radius: 5px; justify-content: space-between; align-items: center; }
							.faq-item-question-text {  }
							.faq-item-question-arrow { width: 10px; transform: rotate(180deg); transition: 0.5s; }
							.faq-item-question-arrow-active { transform: rotate(270deg); }
							.faq-item-question-discord { padding: 10px; background-color: #7289da; text-decoration: none; color: white;  border-radius: 5px; transition: 0.2s; font-weight: 600;
								&:hover { background-color: white; color: #23272a; } }
						.faq-item-answer { display: flex; justify-content: center; transition: 0.5s; height: 0px; overflow: hidden; width: 70%; color: black; margin-top: 10px; font-weight: 600;
							@media only screen and (max-width: 1000px) { width: auto; }
							@media only screen and (max-width: 500px) { width: auto; font-size: 16px; } }
						.faq-item-answer-active { height: 70px;
							@media only screen and (max-width: 1000px) { height: 100px; }
							@media only screen and (max-width: 500px) { height: 150px; } }
							.faq-item-answer-text { padding: 5px 20px; }
	.section-curve-bottom { width: 100%; margin-bottom: 0px; position: relative; bottom: 2px; transform: rotate(180deg); }

