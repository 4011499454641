.team-wrapper { display: block; margin-bottom: 100px; padding-top: 150px; }
	.team { display: block; padding: 0px 50px; }
		.team-title { display: block; background: -webkit-linear-gradient( -70deg, #D94A8F 0%, #34CBCB 100%); -webkit-background-clip: text; -webkit-text-fill-color: transparent; -webkit-box-decoration-break: clone; font-size: 64px; padding-bottom: 30px; font-weight: 700; margin: 0 auto 50px auto; text-align: center; flex-wrap: wrap;
			@media only screen and (max-width: 1000px) { font-size: 48px; } }
		.team-container { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-column-gap: 50px; grid-row-gap: 50px; align-items: center; position: relative;
			@media only screen and (max-width: 1000px) { grid-template-columns: 1fr 1fr; grid-column-gap: 30px; grid-row-gap: 30px; }
			@media only screen and (max-width: 600px) { grid-template-columns: 1fr; } }
			.team-container-background1 { position: absolute; z-index: -1; left: -150px; }
			.team-container-background2 { position: absolute; z-index: -1; right: -100px; bottom: 0px;
				@media only screen and (max-width: 1000px) { right: 100px; } }
			.team-card { display: block; background-color: #081C3D; border-radius: 10px; overflow: hidden;
				@media only screen and (max-width: 1000px) { margin: 0 auto; width: 80%; } } // 081C3D
				.team-card-img { display: block; width: 80%; margin: 20px auto 30px auto; }
				.team-card-text { display: block; text-align: center; }
					.team-card-text-name { display: block; font-size: 20px; margin-bottom: 10px; }
					.team-card-text-position { display: block; color: #a7a7a7; margin-bottom: 20px; }
					.team-card-text-medias { display: flex; align-items: center; justify-content: center; margin-bottom: 20px; }
						.team-card-text-media { width: 30px; height: 30px; border-radius: 5px; background-color: #061630; margin: 0px 5px; padding: 5px; cursor: pointer;  }