.kinds { display: block; background-color: #040F21; border-radius: 20px; color: white; margin: 100px 0px; padding: 50px 0px 20px 0px; position: relative;
	&::before { content: "{"; position: absolute; font-size: 400px; bottom: 16%; color: #061630;
		@media only screen and (max-width: 1000px) { display: none; } }
	&::after { content: "}"; position: absolute; font-size: 400px; bottom: 16%; right: 0px; color: #061630;
		@media only screen and (max-width: 1000px) { display: none; } } }
	.kinds-title { display: block; width: 50%; margin: 0 auto; text-align: center; background: -webkit-linear-gradient( -70deg, #D94A8F 0%, #34CBCB 100%); -webkit-background-clip: text; background-clip: text; -webkit-text-fill-color: transparent; -webkit-box-decoration-break: clone; font-size: 50px; padding-bottom: 30px; font-weight: 700; margin-bottom: 50px;
		@media only screen and (max-width: 1000px) { font-size: 36px; width: 70%; } }
	.kinds-items { display: grid; grid-template-columns: auto auto; width: 70%; margin: 0px auto 50px auto; grid-column-gap: 30px; grid-row-gap: 30px;
		@media only screen and (max-width: 600px) { grid-template-columns: 1fr; grid-column-gap: 30px; grid-row-gap: 30px; } }
		.kinds-item { display: block; background-color: #061630; color: black; line-height: 1.3em; padding: 30px 50px; text-align: center; border-radius: 10px; transition: 0.2s; 
			&:hover { transform: translateY(-5px); }
			@media only screen and (max-width: 900px) { padding: 30px 10px; } }
			.kinds-item-title { display: block; margin-bottom: 20px; font-size: 24px; font-weight: 700; color: white; }
			.kinds-item-text { display: block; color: #a7a7a7; width: 90%; margin: 0 auto; font-size: 18px;
				@media only screen and (max-width: 900px) { width: auto; font-size: 16px; } }
