.navbar-wrapper { display: block; padding-top: 30px; }
	.navbar { display: flex; justify-content: space-between; font-size: 16px; align-items: center; z-index: 9; position: relative; }
		.navbar-logo { display: flex; align-items: center; cursor: pointer; padding-right: 10px; transition: 0.2s;
			&:hover { opacity: 0.6; } }
			.navbar-logo-img { display: block;
				@media only screen and (max-width: 700px) { width: 50px; } }
				.navbar-logo-text { display: block; margin-left: 10px;
					@media only screen and (max-width: 700px) { font-size: 24px; } }
		.navbar-links { display: flex;
			@media only screen and (max-width: 700px) { display: none; } }
			.navbar-link { display: block; padding: 10px 10px; transition: 0.2s; margin: 0px 10px; position: relative; transition: 0.5s;
				&::after { content: ''; position: absolute;  width: 0%; height: 3px; background-color: #8d8d8d; bottom: 0; left: 50%; transform: translate(-50%, 0); border-radius: 20px; transition: 0.5s; background: rgb(217,74,143);
					background: radial-gradient(circle, rgba(217,74,143,1) 0%, rgba(0,212,255,1) 100%); }
				&:hover::after { width: 100%; } }
		.navbar-hamburger { display: none; width: 40px; height: 36px; flex-direction: column; justify-content: space-between;
			@media only screen and (max-width: 700px) { display: block; display: flex; } }
			.navbar-hamburger-layer { background-color: white; height: 7px; width: 100%; border-radius: 2px; transition: 0.5s; }
			.navbar-hamburger-layer-top { transform: translateY(15px) rotate(45deg); }
			.navbar-hamburger-layer-mid { opacity: 0; }
			.navbar-hamburger-layer-bot { transform: translateY(-15px) rotate(-45deg); }
		.navbar-account { display: flex; 
			@media only screen and (max-width: 700px) { display: none; } }
			.navbar-login { display: block; padding: 10px 10px; transition: 0.2s;
				&:hover{ opacity: 0.6; } }
			.navbar-register { display: block; padding: 10px 10px; border: 2px solid white; border-radius: 7px; transition: 0.2s;
				&:hover { background-color: white; color: black; } }

	.navbar-phone { display: none; justify-content: space-between;  position: fixed; width: 50%; height: 100%; top: 0px; left: 0px; z-index: 10; text-align: center; transition: 0.5s; flex-direction: column; font-size: 20px; border-top-right-radius: 15px; border-bottom-right-radius: 15px; background: rgb(217,74,143); background: linear-gradient(338deg, rgba(217,74,143,1) 0%, rgba(4,209,253,0.90) 100%, rgba(0,212,255,0.95) 100%);
		@media only screen and (max-width: 700px) { display: flex; } }
	.navbar-phone-active { left: -50%; }
		.navbar-phone-link-container { padding-top: 20px; }
			.navbar-phone-link { display: block; padding: 20px; }
		.navbar-phone-acc-container { display: flex; flex-direction: column; padding-bottom: 20px; }
			.navbar-phone-line { display: block; height: 10px; width: 100%; background-color: #fff	; margin-bottom: 20px; }
			.navbar-phone-acc { padding: 20px; }
